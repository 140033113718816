/* This is necessary for the google autocomplete to show up on top of the modal */
.pac-container {
    z-index: 100000;
}

/* These are needed for removing unnecessary buttons for the QR code scanner */
#html5-qrcode-anchor-scan-type-change {
  opacity: 0;
  pointer-events: none;
}

#html5-qrcode-button-camera-stop {
  opacity: 0;
  pointer-events: none;
}
